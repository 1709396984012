var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5Ydl387US8ntu5uJ_enmD"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs"

Sentry.init({
  dsn: "https://1a30455d40c616d72b640d4e2704154b@o4505408705396736.ingest.sentry.io/4506110856200192",
  // Replay may only be enabled for the client-side
  integrations: [new Sentry.Replay()],

  sampleRate: parseFloat(process.env.SENTRY_SAMPLE_RATE || "0.1"),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE || "0.1"),

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: parseFloat(
    process.env.SENTRY_SESSION_REPLAY_SAMPLE_RATE || "0.1"
  ),
  replaysOnErrorSampleRate: parseFloat(
    process.env.SENTRY_ERROR_REPLAY_SAMPLE_RATE || "0.1"
  )

  // ...

  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
